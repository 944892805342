import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <h4>
        copyright&copy;{new Date().getFullYear()} <span>Dilaram</span> all
        rights reserved
      </h4>
    </footer>
  )
}

export default Footer
